import request from '@/utils/requestV2'
const qs = require('qs')

// 更改结算单编号（改statementId对应结算单的编号为statementCode）
export function changeStatementCode (data) {
  return request({
    url: '/ooh-scp/v1/statement/changestatementcode',
    method: 'post',
    data: qs.stringify(data)
  })
}
// 新增结算单草稿
export function createStatementDraft (data) {
  return request({
    url: '/ooh-scp/v1/statement/createstatementdraft',
    method: 'post',
    data: qs.stringify(data)
  })
}
// 删除结算单
export function deleteStatement (data) {
  return request({
    url: '/ooh-scp/v1/statement/deletestatement',
    method: 'post',
    data: qs.stringify(data)
  })
}
// 查看发布结算单的详情(包含服务费和支付方式详情)
export function getStatementDetail (data) {
  return request({
    url: '/ooh-scp/v1/statement/getstatementdetail',
    method: 'post',
    data: qs.stringify(data)
  })
}
// 根据结算单版本id查看发布结算单的详情
export function getStatementDetailByVersionId (data) {
  return request({
    url: '/ooh-scp/v1/statement/getStatementDetailByVersionId',
    method: 'post',
    data: qs.stringify(data)
  })
}

// 查看结算单作废备注
export function getStatementInvalidmark (data) {
  return request({
    url: '/ooh-scp/v1/statement/getstatementinvalidmark',
    method: 'post',
    data: qs.stringify(data)
  })
}
// 获取预订单中的品牌名作为结算单名的默认值
export function getStatementName (data) {
  return request({
    url: '/ooh-scp/v1/statement/getstatementname',
    method: 'post',
    data: qs.stringify(data)
  })
}
// 条件查询结算单列表，分页显示
export function getStatementPage (data) {
  return request({
    url: '/ooh-scp/v1/statement/getstatementpage',
    method: 'post',
    data: qs.stringify(data)
  })
}
// 作废结算单
export function invalidStatement (data) {
  return request({
    url: '/ooh-scp/v1/statement/invalidstatement',
    method: 'post',
    data: qs.stringify(data)
  })
}
// 更新结算单
export function updateStatement (data) {
  return request({
    url: '/ooh-scp/v1/statement/updatestatement',
    method: 'post',
    data: qs.stringify(data)
  })
}

/**
 * 获取结算单回款情况
 * @param {Object} data
 */
export function getStatementReceiveList (data) {
  return request({
    url: '/ooh-scp/v1/statement/getreceivelist',
    method: 'post',
    data: qs.stringify(data)
  })
}
