<template>
  <div>
    <Row :gutter="5" class="p-b-5">
      <i-col :xs="24" :sm="12" :md="6" class="m-b-5">
        <DatePicker size="small" placeholder="结算单签订时间"  v-model="signDate" type="daterange" style="width:100%"></DatePicker>
      </i-col>
      <i-col :xs="24" :sm="12" :md="6" class="m-b-5">
        <DatePicker size="small" placeholder="档期开始结束时间"   v-model="publishDate" type="daterange" style="width:100%"></DatePicker>
      </i-col>
       <i-col  v-if="isAuth('Statement_Manage_View')" :xs="24" :sm="12" :md="6" class="m-b-5">
        <Select size="small" v-model="statementQuery.companyId" placeholder="所属公司" clearable @on-change="companyChange">
          <Option v-for="(channel,index) in channellist" :key="index" :value="channel.id">{{channel.name}}</Option>
        </Select>
      </i-col>
        <i-col  v-if="isAuth('Statement_Manage_View')" :xs="24" :sm="6" :md="5" class="m-b-5">
                <Select size="small" v-model="statementQuery.departmentId" placeholder="所属部门" clearable @on-change="departmentChange">
                  <Option v-for="(department,index) in departments" :key="index" :value="department.id">{{department.name}}</Option>
                </Select>
            </i-col>
      <i-col v-if="isAuth('Statement_Manage_View')"  :xs="24" :sm="12" :md="6" class="m-b-5">
        <Select size="small" v-model="statementQuery.userId" placeholder="归属销售" clearable>
          <Option v-for="user in channelUsers" :key="user.id" :value="user.id">{{ user.name}}</Option>
        </Select>
      </i-col>
      <i-col :xs="24" :sm="12" :md="6" class="m-b-5">
        <i-select size="small" placeholder="状态" v-model="statementQuery.status" multiple>
          <i-option v-for="item in statusArray" :key='item.id' :value="item.id">{{item.name}}</i-option>
        </i-select>
      </i-col>
      <i-col  :xs="24" :sm="12" :md="6" class="m-b-5">
                <i-select size="small" placeholder="文件状态" v-model="statementQuery.sealStatus" clearable>
                    <i-option v-for="item in sealstatusArray" :key='item.seal' :value="item.seal">{{item.sealName}}</i-option>
                </i-select>
            </i-col>
      <i-col :xs="24" :sm="12" :md="6" class="m-b-5">
        <i-input size="small" placeholder="关键字：支持名称查询" v-model="statementQuery.keyword">
        </i-input>
      </i-col>
      <i-col :xs="24" :sm="12" :md="6" class="m-b-5">
        <i-button size="small"  type="primary"  icon='ios-search'  @click="handleSearch">搜索</i-button>
        <i-button size="small"  type="success" class="m-l-5" v-if="isAuth('Statement_DownloadList')" @click="handleExportExcel">导出Excel</i-button>
      </i-col>
    </Row>
    <div class="table-responsive">
      <i-table stripe  :data="list" :columns="titles"  @on-sort-change="handleTableSort"></i-table>
      <div class="paging_style">
        <Page size="small" :total="total"  :current="statementQuery.pageNumber" :page-size="statementQuery.pageSize"  @on-change="changePage" show-total show-elevator ></Page>
      </div>
    </div>
  </div>
</template>

<script>
import { getcontractSealStatusList } from '@/api/scp/contract'

import { getStatementPage, deleteStatement } from '@/api/scp/statement'
import { toMoney } from '@/utils/wnumb_own'
import { GetCurrentSchedule } from '@/utils/dateFormat.js'
import { formatStatementStatus } from '@/utils/tagStatus'
import { getCompanyChild } from '@/api/os/company'
import { getSellerPage } from '@/api/os/companyuser'
import { downloadFileRequest } from '@/utils/download'

export default {
  props: {
    isManage: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      channellist: [],
      departments: [],
      channelUsers: [],
      total: 15,
      list: [],
      signDate: [],
      publishDate: [],
      titles: [
        {
          title: '编码',
          key: 'code'
          // sortable: 'custom'
        },
        {
          title: '发布类型',
          key: 'contractCategoryName'
          // sortable: 'custom'
        },
        { title: '广告主', key: 'advertiserName' },
        { title: '品牌', key: 'name' },
        {
          title: '起止日期',
          render: (h, data) => {
            return h('div', [
              h('span', GetCurrentSchedule(data.row.startDate, data.row.endDate))
            ])
          }
        },
        {
          title: '最后更新时间',
          key: 'updateTime'
        },
        {
          title: '审批通过时间',
          key: 'approvedTime'
        },
        {
          title: '金额',
          key: 'amount',
          // sortable: 'custom',
          align: 'right',
          render: (h, data) => {
            return h('span', {
              attrs: {
                style: 'color:#44bd32'
              }
            }, toMoney(data.row.amount))
          }
        },
        {
          title: '已回款金额',
          key: 'receivedAmount',
          // sortable: 'custom',
          align: 'right',
          render: (h, data) => {
            return h('span', {
              attrs: {
                style: 'color:#44bd32'
              }
            }, toMoney(data.row.receivedAmount || 0))
          }
        },
        {
          title: '状态',
          align: 'center',
          width: 110,
          render: (h, data) => {
            if (data.row.invaliding) {
              return h('div', [h('tag', { props: { color: '#a59426' } }, '作废中')])
            } else {
              return formatStatementStatus(h, data.row.status, data.row.statusName)
            }
          }
        },
        {
          title: '合同文件状态',
          key: 'sealStatus',
          minWidth: 60,
          align: 'center',
          render: (h, params) => {
            return h('div', [
              h(
                'span',
                params.row.sealStatusName
              )
            ])
          }
        },
        {
          title: '操作',
          render: (h, data) => {
            const updateButton = h('a', {
              style: { 'margin-left': '5px' },
              on: {
                click: () => {
                  this.update(data.row)
                }
              }

            }, '编辑')
            const deleteButton = h('a', {
              style: { 'margin-left': '5px', color: '#ef4f4f' },
              on: {
                click: () => {
                  this.handleDelete(data.row)
                }
              }
            }, '删除')
            const detailButton = h('a', {
              style: { 'margin-left': '5px' },
              on: {
                click: () => {
                  this.showDetail(data.row)
                }
              }
            }, '详情')
            const settingButton = h('a', {
              style: {
                marginRight: '5px'
              },
              on: {
                click: () => {
                  this.$store.commit('set_contract_contractId', data.row.id)
                  this.$store.commit('set_contract_statementSetting', true)
                }

              }
            }, '设置')
            const returnBtn = [detailButton]
            if (this.isAuth('Contract_FileSetting') || this.isAuth('Contract_fileUpload') || this.isAuth('Contract_fileDelete')) {
              returnBtn.push(settingButton)
            }
            // 开始返回可用的操作按钮
            if (!this.isAuth('Statement_Edit')) {
            } else if (data.row.status === -1) { // 废弃
            } else if (data.row.status === 0) { // 草稿
              returnBtn.push(updateButton)
              returnBtn.push(deleteButton)
            } else if (data.row.status === 6) { // 已拒绝
              returnBtn.push(updateButton)
            }
            return h('div', returnBtn)
          }
        }
      ],
      statusArray: [],

      sealstatusArray: []
    }
  },
  methods: {
    formatDate (date) {
      if (!date) {
        return null
      } else {
        return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`
      }
    },
    initPage () {
      this.channellist = []
      getCompanyChild({ companyId: this.$store.getters.token.userInfo.companyId, type: this.$store.getters.token.userInfo.publisherId === this.$store.getters.token.userInfo.companyId ? 1 : 2, childType: 2, companyType: 1 }).then(res => {
        let array = [{
          id: res.companyId, name: res.companyName
        }]
        if (res.children.length) {
          array = array.concat(this.formatCompanyArray(res.children).filter(x => x.id !== res.companyId))
        }
        this.channellist = array
        if (!this.statementQuery.companyId) {
          if (array.length > 1 && this.isAuth('Statement_Manage_View')) {
            this.statementQuery.companyId = null
          } else {
            this.statementQuery.companyId = this.$store.getters.token.userInfo.companyId
          }
        }
        this.initdepartments()
      })
    },
    companyChange () {
      this.statementQuery.departmentId = ''
      this.statementQuery.userId = ''
      this.initdepartments()
    },
    departmentChange () {
      this.statementQuery.userId = ''
      this.getChannelUserList()
    },
    initdepartments () {
      this.departments = []
      if (this.statementQuery.companyId) {
        getCompanyChild({ companyId: this.statementQuery.companyId, type: 2, childType: 2, companyType: 2 }).then(res => {
          let array = [{
            id: res.companyId, name: res.companyName
          }]
          if (res.children.length) {
            array = array.concat(this.formatCompanyArray(res.children).filter(x => x.id !== res.companyId))
          }
          this.departments = array

          // this.statementQuery.departmentId = null
          this.getChannelUserList()
        })
      } else {
        this.getChannelUserList()
      }
    },

    // 重组公司列表结构
    formatCompanyArray (companyTree) {
      let resultArray = []
      companyTree.forEach(item => {
        resultArray.push({ id: item.companyId, name: item.companyName })
        if (item.children.length) {
          resultArray = resultArray.concat(this.formatCompanyArray(item.children))
        }
      })
      return resultArray
    },
    getChannelUserList () {
      this.channelUsers = []
      const data = {
        companyId: this.statementQuery.departmentId || this.statementQuery.companyId,
        pageNumber: 1,
        pageSize: 1000
      }
      if (data.companyId) {
        getSellerPage(data).then(response => {
          this.channelUsers = response.list
        })
      } else {
        this.statementQuery.userId = ''
      }
      this.getPage()
    },
    getPage () {
      if (this.statementQuery.status.length > 0) {
        this.statementQuery.statusList = JSON.stringify(this.statementQuery.status)
      } else {
        this.statementQuery.statusList = JSON.stringify(this.statusArray.map(x => { return x.id }))
      }
      this.$store.commit('set_query_statementQuery', this.statementQuery)
      getStatementPage(this.statementQuery).then(res => {
        this.list = res.list
        this.total = res.totalRow
      })
    },
    changePage (page) {
      this.statementQuery.pageNumber = page
      this.getPage()
    },

    update (statement) {
      this.$emit('on-edit', statement.id, statement.versionId)
    },
    showDetail (stateMent) {
      this.$emit('on-detail', stateMent.id, stateMent.versionId)
      // this.$router.push({ path: '/statement/detail/' + stateMent.id })
    },
    handleDelete (statement) {
      this.$Modal.confirm({
        title: '您是否要删除该结算单？',
        content: '删除该结算单后，您可能需要重新创建一个',
        onOk: () => {
          this.deleteStatement(statement.id)
        }
      })
    },
    deleteStatement (statementId) {
      deleteStatement({ statementId: statementId }).then(res => {
        if (res && !res.errcode) {
          this.$Notice.success({ title: '删除成功', desc: '您已成功删除了一个结算单' })
          this.statementQuery.pageNumber = 1
          this.getPage()
        }
      })
    },
    handleSearch () {
      this.statementQuery.pageNumber = 1
      this.statementQuery.startDate = this.formatDate(this.publishDate[0])
      this.statementQuery.endDate = this.formatDate(this.publishDate[1])
      this.statementQuery.signStartDate = this.formatDate(this.signDate[0])
      this.statementQuery.signEndDate = this.formatDate(this.signDate[1])
      this.getPage()
    },
    handleExportExcel () {
      if (this.statementQuery.status.length > 0) {
        this.statementQuery.statusList = JSON.stringify(this.statementQuery.status)
      } else {
        this.statementQuery.statusList = JSON.stringify(this.statusArray.map(x => { return x.id }))
      }
      const query = Object.assign({}, this.statementQuery, { subjectTypeList: JSON.stringify([1, 3]) })
      downloadFileRequest(process.env.VUE_APP_API_URL_V2 + '/ooh-scp/v1/statementfile/exportexcel', query, '结算单列表.xlsx')
    },
    handleTableSort (column) {
      this.statementQuery.pageNumber = 1
      switch (column.key) {
        case 'code':
          this.statementQuery.orderByCodeAsc = (column.order === 'asc')
          break
        case 'amount':
          this.statementQuery.orderByAmountAsc = (column.order === 'asc')
          break
        case 'receivedAmount':
          this.statementQuery.orderByReceivedAmountAsc = (column.order === 'asc')
          break
      }
      this.getPage()
    },
    // 加载可用的状态
    initStatusArray () {
      const that = this
      that.statusArray = [
        { id: 0, name: '草稿' },
        { id: -1, name: '已废弃' }
      ]
      const manageStatus = [
        { id: 4, name: '待审批' },
        { id: 5, name: '已通过' },
        { id: 6, name: '已拒绝' }
        // { id: 9, name: '已归档' }
      ]
      if (that.isAuth('Statement_Sale_View')) {
        that.statusArray = that.statusArray.concat(manageStatus)
      } else {
        that.statusArray = manageStatus
      }
    },

    getSealStatusArray () {
      getcontractSealStatusList({}).then(res => {
        this.sealstatusArray = res
      })
    }
  },
  created () {
    this.statementQuery.userId = this.isAuth('Statement_Manage_View') ? this.statementQuery.userId : this.$store.getters.token.userInfo.userId
    this.getSealStatusArray()
    this.initStatusArray()
    this.initPage()
  },
  computed: {
    statementQuery () {
      return this.$store.state.contractQuery.statementQuery
    },
    beginUpdate () {
      return this.$store.state.contract.beginUpdate
    }
  },
  watch: {
    beginUpdate () {
      this.initPage()
    }
  }

}
</script>
