import request from '@/utils/requestV2'
const qs = require('qs')

// 查看合同详情
export function checkContractDetail (data) {
  return request({
    url: '/ooh-scp/v1/contract/checkcontractdetail',
    method: 'post',
    data: qs.stringify(data)
  })
}

// 查看合同详情
export function checkContractDetailByVersionId (data) {
  return request({
    url: '/ooh-scp/v1/contract/checkContractDetailByVersionId',
    method: 'post',
    data: qs.stringify(data)
  })
}

// 创建一份合同草稿
export function createDraft (data) {
  return request({
    url: '/ooh-scp/v1/contract/createdraft',
    method: 'post',
    data: qs.stringify(data)
  })
}
// 基于确认单 创建合同草稿
export function createdraftfromconfirmation (data) {
  return request({
    url: '/ooh-scp/v1/contract/createdraftfromconfirmation',
    method: 'post',
    data: qs.stringify(data)
  })
}

// 删除合同
export function deleteContract (data) {
  return request({
    url: '/ooh-scp/v1/contract/deletecontract',
    method: 'post',
    data: qs.stringify(data)
  })
}

// 合同审批表归档和合同归档
export function fileContract (data) {
  return request({
    url: '/ooh-scp/v1/contract/filecontract',
    method: 'post',
    data: qs.stringify(data)
  })
}

// 获取预订单中的品牌名作为结算单名的默认值
export function getBrandName (data) {
  return request({
    url: '/ooh-scp/v1/contract/getbrandname',
    method: 'post',
    data: qs.stringify(data)
  })
}

// 查看合同作废备注
export function getContractInvalidmark (data) {
  return request({
    url: '/ooh-scp/v1/contract/getcontractinvalidmark',
    method: 'post',
    data: qs.stringify(data)
  })
}

// 条件查询合同列表，分页显示
export function getContractPage (data) {
  return request({
    url: '/ooh-scp/v1/contract/getcontractpage',
    method: 'post',
    data: qs.stringify(data)
  })
}

// 获取合同的甲方详情(只含基本信息)
export function getCustomerInfo (data) {
  return request({
    url: '/ooh-scp/v1/contract/getcustomerinfo',
    method: 'post',
    data: qs.stringify(data)
  })
}

// 获取合同付款方详情(只含基本信息)
export function getPayerInfo (data) {
  return request({
    url: '/ooh-scp/v1/contract/getpayerinfo',
    method: 'post',
    data: qs.stringify(data)
  })
}

// 作废合同
export function invalidContract (data) {
  return request({
    url: '/ooh-scp/v1/contract/invalidcontractv2',
    method: 'post',
    data: qs.stringify(data)
  })
}

// 查看合同作废记录
export function getContractInvalidInfo (data) {
  return request({
    url: '/ooh-scp/v1/contract/getcontractinvalidinfov2',
    method: 'post',
    data: qs.stringify(data)
  })
}

// 更新合同
export function updateDraft (data) {
  return request({
    url: '/ooh-scp/v1/contract/updatedraft',
    method: 'post',
    data: qs.stringify(data)
  })
}

// 预测合同绩效
export function predictCommission (data) {
  return request({
    url: '/ooh-scp/v1/contract/predictcommission',
    method: 'post',
    data: qs.stringify(data)
  })
}

// 获取媒介主合同可用的销售绩效类型
export function getPublisherContractCommissionTypeList (data) {
  return request({
    url: '/ooh-scp/v1/contract/getpublishercontractcommissiontypelist',
    method: 'post',
    data: qs.stringify(data)
  })
}

// 获取媒介主合同可用的销售绩效类型
export function confirmPayedForSmallCustomerOrder (data) {
  return request({
    url: '/ooh-scp/v1/contract/confirmpayedforsmallcustomerorder',
    method: 'post',
    data: qs.stringify(data)
  })
}

// 更改合同编号（改contractId对应合同的编号为contractCode）
export function changeContractCode (data) {
  return request({
    url: '/ooh-scp/v1/contract/changecontractcode',
    method: 'post',
    data: qs.stringify(data)
  })
}

/**
 * 获取合同回款情况
 * @param {Object} data
 */
export function getReceiveList (data) {
  return request({
    url: '/ooh-scp/v1/contract/getreceivelist',
    method: 'post',
    data: qs.stringify(data)
  })
}
// 标记合同文件状态属性
export function sealContractSealStatus (data) {
  return request({
    url: '/ooh-scp/v1/contract/setcontractsealstatus',
    method: 'post',
    data: qs.stringify(data)
  })
}
// 设置合同可见范围
export function setContractVisibleScope (data) {
  return request({
    url: '/ooh-scp/v1/contract/setcontractvisiblescope',
    method: 'post',
    data: qs.stringify(data)
  })
}

export function getContractModuleList (data) {
  return request({
    url: '/ooh-scp/v1/contract/getContractModuleList',
    method: 'post',
    data: qs.stringify(data)
  })
}
// 合同中的订单发生变更的合同数
export function getChangedContractOrderNum (data) {
  return request({
    url: '/ooh-scp/v1/contract/getChangedContractOrderNum',
    method: 'post',
    data: qs.stringify(data)
  })
}
// 合同文件状态枚举
export function getcontractSealStatusList (data) {
  return request({
    url: '/ooh-scp/v1/contract/getContractSealStatusList',
    method: 'post',
    data: qs.stringify(data)
  })
}

// 撤销合同审批,合同退回草稿状态
export function withdrawContractApproval (data) {
  return request({
    url: '/ooh-scp/v1/contract/withdrawContractApproval',
    method: 'post',
    data: qs.stringify(data)
  })
}
// 根据合同版本id获取对应的审批类型
export function getProcessTypeByVersionId (data) {
  return request({
    url: '/ooh-scp/v1/contract/getProcessTypeByVersionId',
    method: 'post',
    data: qs.stringify(data)
  })
}
// 获取合同的签约媒介使用费明细
export function getContractSignUseFee (data) {
  return request({
    url: '/ooh-scp/v1/contract/getContractSignUseFee',
    method: 'post',
    data: qs.stringify(data)
  })
}

// 获取合同的签约服务费明细
export function getContractSignServiceFee (data) {
  return request({
    url: '/ooh-scp/v1/contract/getContractSignServiceFee',
    method: 'post',
    data: qs.stringify(data)
  })
}
