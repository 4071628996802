import request from '@/utils/requestV2'
const qs = require('qs')

/**
 * 获取公司下的销售
 * @param {Object} data
 */
export function getSellerPage (data) {
  return request({
    url: '/ooh-os/v1/companyuser/getsellerpage',
    method: 'post',
    data: qs.stringify(data)
  })
}
// 设置销售是否能做公益
export function setCommunity (data) {
  return request({
    url: '/ooh-os/v1/companyuser/setcommunity',
    method: 'post',
    data: qs.stringify(data)
  })
}
